@charset "UTF-8";
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: "BrandonText-BoldItalic";
  src: url("2981F0_0_0.eot");
  src: url("2981F0_0_0.eot?#iefix") format("embedded-opentype"), url("2981F0_0_0.woff") format("woff"), url("2981F0_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-Bold";
  src: url("2981F0_1_0.eot");
  src: url("2981F0_1_0.eot?#iefix") format("embedded-opentype"), url("2981F0_1_0.woff") format("woff"), url("2981F0_1_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-BlackItalic";
  src: url("2981F0_2_0.eot");
  src: url("2981F0_2_0.eot?#iefix") format("embedded-opentype"), url("2981F0_2_0.woff") format("woff"), url("2981F0_2_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-Black";
  src: url("2981F0_3_0.eot");
  src: url("2981F0_3_0.eot?#iefix") format("embedded-opentype"), url("2981F0_3_0.woff") format("woff"), url("2981F0_3_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-LightItalic";
  src: url("2981F0_4_0.eot");
  src: url("2981F0_4_0.eot?#iefix") format("embedded-opentype"), url("2981F0_4_0.woff") format("woff"), url("2981F0_4_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-Light";
  src: url("2981F0_5_0.eot");
  src: url("2981F0_5_0.eot?#iefix") format("embedded-opentype"), url("2981F0_5_0.woff") format("woff"), url("2981F0_5_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-RegularItalic";
  src: url("2981F0_6_0.eot");
  src: url("2981F0_6_0.eot?#iefix") format("embedded-opentype"), url("2981F0_6_0.woff") format("woff"), url("2981F0_6_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-Regular";
  src: url("2981F0_7_0.eot");
  src: url("2981F0_7_0.eot?#iefix") format("embedded-opentype"), url("2981F0_7_0.woff") format("woff"), url("2981F0_7_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-Medium";
  src: url("2981F0_8_0.eot");
  src: url("2981F0_8_0.eot?#iefix") format("embedded-opentype"), url("2981F0_8_0.woff") format("woff"), url("2981F0_8_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-ThinItalic";
  src: url("2981F0_9_0.eot");
  src: url("2981F0_9_0.eot?#iefix") format("embedded-opentype"), url("2981F0_9_0.woff") format("woff"), url("2981F0_9_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-Thin";
  src: url("2981F0_A_0.eot");
  src: url("2981F0_A_0.eot?#iefix") format("embedded-opentype"), url("2981F0_A_0.woff") format("woff"), url("2981F0_A_0.ttf") format("truetype");
}
@font-face {
  font-family: "BrandonText-MediumItalic";
  src: url("2981F0_B_0.eot");
  src: url("2981F0_B_0.eot?#iefix") format("embedded-opentype"), url("2981F0_B_0.woff") format("woff"), url("2981F0_B_0.ttf") format("truetype");
}
.w-100 {
  width: 100% !important;
}

.position-relative {
  position: relative !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.invisible {
  visibility: hidden !important;
}

[data-controller=slider] [data-slider-target=container] {
  display: flex !important;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  width: 100% !important;
  transition: margin-left 0.5s ease;
}
[data-controller=slider] [data-slider-target=container] [data-slider-target=item] {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

body.news_categories.show #content, body.news.show #content {
  font-size: 0.69444vw;
  position: relative;
  padding: 0;
  margin-top: -10px;
  margin-bottom: 0;
  border-top: 1px solid #e6e6e6;
  background-color: #f2f2f2;
}
body.news_categories.show #content:after, body.news.show #content:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
body.news_categories.show #content nav, body.news.show #content nav {
  display: block;
}
body.news_categories.show #content nav.category, body.news.show #content nav.category {
  padding: 10px 0;
  margin-bottom: 10px;
  height: 48px;
  background-color: #fff;
  text-align: center;
}
body.news_categories.show #content nav.category a, body.news_categories.show #content nav.category a:link, body.news_categories.show #content nav.category a:visited, body.news.show #content nav.category a, body.news.show #content nav.category a:link, body.news.show #content nav.category a:visited {
  display: inline-block;
  line-height: 28px;
  margin: 10px 20px;
  text-decoration: none;
  font-size: 1.7em;
}
body.news_categories.show #content nav.category a:hover, body.news_categories.show #content nav.category a:active, body.news_categories.show #content nav.category a.active, body.news_categories.show #content nav.category a:link:hover, body.news_categories.show #content nav.category a:link:active, body.news_categories.show #content nav.category a:link.active, body.news_categories.show #content nav.category a:visited:hover, body.news_categories.show #content nav.category a:visited:active, body.news_categories.show #content nav.category a:visited.active, body.news.show #content nav.category a:hover, body.news.show #content nav.category a:active, body.news.show #content nav.category a.active, body.news.show #content nav.category a:link:hover, body.news.show #content nav.category a:link:active, body.news.show #content nav.category a:link.active, body.news.show #content nav.category a:visited:hover, body.news.show #content nav.category a:visited:active, body.news.show #content nav.category a:visited.active {
  color: #fc9700;
  border-bottom: 2px solid #fc9700;
}

body.news.show #content {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 4.166vw 0;
  border-top: none;
  background-color: #fff;
}
body.news.show #content h1 {
  font-size: 2.8em;
  color: #fc9700;
  width: 700px;
  margin: 0 auto;
  margin-top: 4.166vw;
  margin-bottom: 1em;
  text-align: center;
}
body.news.show #content div.pictures {
  min-height: 414px;
  width: 700px;
  margin: 0 auto;
}
body.news.show #content div.pictures img {
  display: block;
  max-width: 100%;
  max-height: 414px;
}
body.news.show #content div.pictures ul, body.news.show #content div.pictures li {
  list-style: none;
  margin: 0;
  padding: 0;
}
body.news.show #content [data-slider-target=container] {
  z-index: 1;
}
body.news.show #content a.slider-arrow {
  position: absolute;
  z-index: 2;
}
body.news.show #content a.slider-arrow.next, body.news.show #content a.slider-arrow.prev {
  top: 200px;
  width: 14px;
  height: 28px;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-size: 14px 28px;
  background-position: center center;
}
body.news.show #content a.slider-arrow.next {
  right: -40px;
  background-image: url("news_next.svg");
}
body.news.show #content a.slider-arrow.prev {
  left: -40px;
  background-image: url("news_prev.svg");
}
body.news.show #content nav.slider-nav {
  margin-top: 20px;
  text-align: center;
}
body.news.show #content nav.slider-nav a {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: transparent;
  border-radius: 5px;
  overflow: hidden;
  text-indent: -999em;
  border: 2px solid #fc9700;
  cursor: pointer;
}
body.news.show #content nav.slider-nav a.active {
  background: #fc9700;
  cursor: default;
}
body.news.show #content p {
  width: 700px;
  margin: 0 auto;
}
body.news.show #content a.order-form {
  display: block;
  margin: 2em auto;
  font-size: 1.62em;
  width: 180px;
  height: 40px;
  line-height: 42px;
  padding: 0 35px 0 10px;
  border: 1px solid #000;
  text-decoration: none;
  background: url("order-form-arrow.svg") no-repeat right center;
}
body.news.show #content a.close-news {
  display: block;
  position: absolute;
  top: 35px;
  right: 35px;
  width: 36px;
  height: 36px;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-size: 36px 36px;
  background-position: center center;
  background-image: url("close_orange.svg");
}

@media screen and (max-width: 1023px) {
  body.news.show #content div.slider {
    max-width: 100%;
    width: auto;
  }
  body.news.show #content div.pictures img {
    max-height: none;
  }
  body.news.show #content a.slider-arrow.next, body.news.show #content a.slider-arrow.prev {
    top: 80px;
  }
  body.news.show #content a.slider-arrow.next {
    right: 40px;
  }
  body.news.show #content a.slider-arrow.prev {
    left: 40px;
  }
}
section.container.news header {
  background-image: url("aktuell_bg.jpg") !important;
  background-color: #fff;
  height: 15.5vw !important;
  position: relative !important;
  margin-bottom: 0 !important;
}
section.container.news aside {
  margin: auto;
  width: 42.7vw;
  height: 9vw;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fc9700;
  text-align: center;
  color: #fff;
  padding: 2em;
}
section.container.news aside h2 {
  color: #fff;
  font-size: 3.24em;
  font-family: BrandonText-Light;
  padding: 0;
}
section.container.news aside p {
  padding: 0;
  margin: 0;
}
section.container.news div.slideshow {
  position: relative;
  overflow: hidden;
  width: 75%;
  height: 29.5vw;
  border-right: 10px solid #fff;
}
section.container.news div.slideshow-container {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 1000%;
  transition: left 1s;
}
section.container.news article {
  display: block;
  box-sizing: border-box;
  background: #fff;
  padding: 10px 0;
  border-left: 10px solid #fff;
  width: 5%;
  float: left;
}
section.container.news article img {
  width: 100%;
  margin-bottom: 1.7vw;
}
section.container.news article h3 {
  font-family: BrandonText-Light;
  font-size: 2.6em;
  white-space: nowrap;
  height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
section.container.news article p {
  padding: 0;
  margin: 0;
}
section.container.news article p:after {
  display: inline-block;
  content: " ";
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-top: -10px;
  margin-bottom: -4px;
  margin-left: 0.8em;
  background: url("news_button_next.svg") no-repeat center center #fc9700;
  background-size: 6px 10px;
}
section.container.news article a:hover, section.container.news article a:active {
  color: #3c4547;
}
section.container.news nav {
  display: block;
  position: absolute;
  top: 12.15vw;
  padding-top: 6vw;
  padding-bottom: 4.88vw;
  right: 0;
  width: 25%;
  margin-left: -10px;
  margin-top: 5%;
  margin-top: 3.35vw;
  text-align: center;
}
section.container.news nav a, section.container.news nav a:link, section.container.news nav a:visited {
  display: inline-block;
  background: #fc9700;
  color: #fff;
  font-size: 10em;
  line-height: 0.65em;
  border-radius: 0.37em;
  text-decoration: none;
  margin: 0 0.1em;
  width: 0.68em;
  height: 0.74em;
}
section.container.news nav a img, section.container.news nav a:link img, section.container.news nav a:visited img {
  height: 0.38em;
}
section.container.news nav a:hover, section.container.news nav a:active, section.container.news nav a:link:hover, section.container.news nav a:link:active, section.container.news nav a:visited:hover, section.container.news nav a:visited:active {
  background: rgb(255, 172.0357142857, 48);
}
section.container.news nav a.next, section.container.news nav a:link.next, section.container.news nav a:visited.next {
  padding-left: 0.06em;
}
section.container.news nav a.prev, section.container.news nav a:link.prev, section.container.news nav a:visited.prev {
  padding-right: 0.06em;
}
section.container.news nav .pagination {
  margin-top: 0.925em;
  color: #fc9700;
  font-size: 3.2em;
  font-family: BrandonText-Light;
}

body.news_categories.show #content div.news.gap {
  background-color: #fff;
  display: block;
  float: left;
  position: relative;
  z-index: 1;
  width: 25%;
  height: 19.444vw;
  margin: 0 -10px 10px -10px;
  border-left: 10px solid #f2f2f2;
  border-right: 10px solid #f2f2f2;
}
body.news_categories.show #content article.quater {
  width: 25%;
  height: 19.444vw;
  float: left;
  margin: 0 -10px 10px -10px;
  border-left: 10px solid #f2f2f2;
  border-right: 10px solid #f2f2f2;
}
body.news_categories.show #content article.news {
  background: url("das_plus_des_monats_bg.svg") no-repeat #fff;
  background-position: right 1.8vw bottom 1.8vw;
  background-size: 1.6vw 1.6vw;
}
body.news_categories.show #content article.news h2 {
  padding: 0 10vw 0 1.4vw;
  white-space: nowrap;
  height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2em;
}
body.news_categories.show #content article.news h3 {
  padding: 0 1.4vw;
  padding-top: 1vw;
  font-size: 1.2em;
  color: #3c4547;
}
body.news_categories.show #content article.news img {
  max-width: 100%;
}
body.news_categories.show #content article.news a {
  text-decoration: none;
}

section.social_media {
  position: relative;
  text-align: center;
}
section.social_media h2 {
  text-transform: none;
  text-align: center;
  padding: 2em 0.695em;
}
section.social_media h2 a {
  color: #fc9700;
}
section.social_media div.slideshow {
  position: relative;
  overflow: hidden;
  width: 1065px;
  height: 460px;
  margin: 0 auto;
  border-bottom: 1px solid #D8DADA;
  margin-bottom: 50px;
}
section.social_media div.slideshow:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
section.social_media div.slideshow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 1000%;
  opacity: 1;
  transition: opacity 1s;
}
section.social_media div.slideshow-container.hidden {
  opacity: 0;
}
section.social_media a.like {
  display: inline-block;
  position: relative;
  font-size: 2em;
  border-bottom: 1px solid #3c4547;
  margin-bottom: 50px;
}
section.social_media a.like:before {
  content: " ";
  position: absolute;
  top: 3px;
  left: -30px;
  width: 18px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url("facebook.svg");
}
section.social_media article.social_media_post {
  text-align: left;
  display: block;
  float: left;
  height: 460px;
  width: 335px;
  margin: 0 10px;
  font-size: 1.4em;
  line-height: 1.4em;
}
section.social_media article.social_media_post h4 {
  display: none;
}
section.social_media article.social_media_post div.image {
  width: 335px;
  height: 335px;
  margin-bottom: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
section.social_media article.social_media_post div.image.no-image {
  background-size: auto;
  background-color: #D8DADA;
}
section.social_media nav.slideshow {
  position: absolute;
  top: 300px;
  max-width: 1270px;
  margin: auto;
  left: 0;
  right: 0;
}
section.social_media nav.slideshow a {
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #eeeeee;
}
section.social_media nav.slideshow a.next {
  right: 0;
}
section.social_media nav.slideshow a img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
section.social_media.instagram {
  background: #3C4547;
}
section.social_media.instagram a {
  color: #fff;
}
section.social_media.instagram a.like {
  border-bottom: 1px solid #fff;
}
section.social_media.instagram a.like:before {
  top: 2px;
  height: 18px;
  background-image: url("instagram.svg");
}
section.social_media.instagram nav.slideshow a {
  background-color: #727879;
}

@media screen and (max-width: 1023px) {
  section.social_media h2 {
    font-size: 8em;
    padding-bottom: 1em;
  }
  section.social_media div.slideshow {
    width: 300px;
    border-bottom: none;
    margin-bottom: 0;
    overflow: visible;
    width: 100%;
    height: auto;
  }
  section.social_media div.slideshow-container {
    position: static;
    width: 100%;
  }
  section.social_media a.like {
    font-size: 7em;
    margin-bottom: 40px;
  }
  section.social_media article.social_media_post {
    display: block;
    float: none;
    height: auto;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10vw;
    font-size: 7em;
  }
  section.social_media article.social_media_post div.image {
    width: 100%;
    height: 90vw;
  }
  section.social_media nav.slideshow {
    position: static;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10vw;
  }
  section.social_media nav.slideshow a {
    display: inline-block;
    position: relative;
    margin: 0 20px;
  }
}
html {
  min-height: 100%;
  background: #f2f2f2;
}

body {
  margin: 0;
  padding: 0 10px;
  font-size: 10px;
  color: #3c4547;
  font-family: BrandonText-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  border: none;
}

p, li {
  font-size: 1.62em;
}

.handle {
  cursor: move;
}

td.handle {
  width: 20px;
}

table.opening-hours th, table.opening-hours td {
  font-size: 1.62em;
}
table.opening-hours th {
  text-align: right;
  font-weight: normal;
}

.orange {
  color: #fc9700;
}

a, a:link, a:visited {
  color: #3c4547;
}
a:hover, a:active, a.active, a:link:hover, a:link:active, a:link.active, a:visited:hover, a:visited:active, a:visited.active {
  color: #fc9700;
}

a.tel, a.tel:link, a.tel:visited {
  text-decoration: none;
}

sup {
  font-size: 0.5em;
}

hr {
  border: none;
  outline: none;
  height: 1px;
  background-color: #3c4547;
}

.botr {
  display: none;
}

header {
  padding-top: 49px;
}

header, body.site.index header.scroll {
  display: block;
  background: #fff;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
header h1, body.site.index header.scroll h1 {
  margin: 0;
  padding: 0;
  text-indent: -9999px;
  display: block;
  position: absolute;
  border-bottom: none;
}
header nav a, header nav a:link, header nav a:visited, body.site.index header.scroll nav a, body.site.index header.scroll nav a:link, body.site.index header.scroll nav a:visited {
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 10px;
}
header nav a:hover, header nav a:active, header nav a.active, header nav a:link:hover, header nav a:link:active, header nav a:link.active, header nav a:visited:hover, header nav a:visited:active, header nav a:visited.active, body.site.index header.scroll nav a:hover, body.site.index header.scroll nav a:active, body.site.index header.scroll nav a.active, body.site.index header.scroll nav a:link:hover, body.site.index header.scroll nav a:link:active, body.site.index header.scroll nav a:link.active, body.site.index header.scroll nav a:visited:hover, body.site.index header.scroll nav a:visited:active, body.site.index header.scroll nav a:visited.active {
  border-bottom: 2px solid #fc9700;
}
header div.content, body.site.index header.scroll div.content {
  background: #fff;
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  margin: 0 -10px;
  height: 49px;
  border-bottom: 1px solid #e6e6e6;
}
header div.content h1, body.site.index header.scroll div.content h1 {
  width: 90px;
  height: 25px;
  background: url("apotheke-oberalm_logo_header_scroll.svg") no-repeat;
  margin-left: -430px;
  left: 50%;
  top: 13px;
}
header div.content nav, body.site.index header.scroll div.content nav {
  padding-top: 17px;
  margin-left: 150px;
}
header div.content nav a, header div.content nav a:link, header div.content nav a:visited, body.site.index header.scroll div.content nav a, body.site.index header.scroll div.content nav a:link, body.site.index header.scroll div.content nav a:visited {
  font-size: 1.2em;
  padding: 7px 0;
}
header a.info, header a.info:visited, header a.info:hover, header a.info:active, body.site.index header.scroll a.info, body.site.index header.scroll a.info:visited, body.site.index header.scroll a.info:hover, body.site.index header.scroll a.info:active {
  position: absolute;
  top: 12px;
  right: 20px;
  color: #fff;
  background: #fc9700;
  font-size: 1.8em !important;
  padding: 0 !important;
  font-family: Courier;
  height: 25px;
  line-height: 25px;
  width: 25px;
  border-radius: 13px;
  text-decoration: none;
  text-align: center;
  border-bottom: none !important;
  text-transform: none !important;
}
header aside.login, body.site.index header.scroll aside.login {
  text-align: center;
  position: relative;
  height: 40px;
  top: 10px;
  left: 50%;
  width: 950px;
  margin-left: -475px;
  z-index: 900;
  color: #fc9700;
  font-size: 1.8em;
}
header aside.login nav, body.site.index header.scroll aside.login nav {
  margin-right: 30px;
  padding-top: 0;
  display: inline;
}
header aside.login nav:before, body.site.index header.scroll aside.login nav:before {
  content: "Admin: ";
}
header aside.login nav a, header aside.login nav a:link, header aside.login nav a:visited, body.site.index header.scroll aside.login nav a, body.site.index header.scroll aside.login nav a:link, body.site.index header.scroll aside.login nav a:visited {
  font-size: 0.9em;
  padding: 5px 0;
}
header aside.login nav a:last-of-type, header aside.login nav a:link:last-of-type, header aside.login nav a:visited:last-of-type, body.site.index header.scroll aside.login nav a:last-of-type, body.site.index header.scroll aside.login nav a:link:last-of-type, body.site.index header.scroll aside.login nav a:visited:last-of-type {
  font-size: 0.9em;
  text-transform: uppercase;
}
header aside.login strong, body.site.index header.scroll aside.login strong {
  font-style: italic;
}
header aside.login a.logout, body.site.index header.scroll aside.login a.logout {
  color: #fc9700;
  background: url("logout.png") no-repeat right top;
  padding-right: 25px;
  text-decoration: none;
}

body.site.index header {
  padding-top: 0;
  height: 293px;
}
body.site.index header div.content {
  position: static;
  height: 293px;
}
body.site.index header.scroll div.content {
  position: fixed;
  width: 100%;
}
body.site.index header h1 {
  width: 220px;
  padding: 0 80px 40px 80px;
  height: 95px;
  background: url("apotheke-oberalm_logo_header_big.svg") no-repeat 80px top;
  margin-left: -190px;
  left: 50%;
  top: 45px;
  border-bottom: 2px solid #cbcbcb;
}
body.site.index header nav {
  margin-left: 0;
  padding-top: 245px;
}
body.site.index header nav a, body.site.index header nav a:link, body.site.index header nav a:visited {
  font-size: 1.44em;
  padding: 10px 0;
}
body.site.index header nav a:last-of-type, body.site.index header nav a:link:last-of-type, body.site.index header nav a:visited:last-of-type {
  padding: 0;
  text-transform: none;
  font-size: 2.2em;
}
body.site.index header a.info {
  font-size: 2.2em;
  top: 25px;
  right: 20px;
  height: 35px;
  line-height: 35px;
  width: 35px;
  text-decoration: none;
  text-align: center;
  border-radius: 18px;
}
body.site.index header aside.login {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 50%;
  width: 950px;
  margin-left: -475px;
  z-index: 2000;
  color: #fc9700;
  font-size: 1.8em;
}
body.site.index header aside.login nav {
  margin-right: 30px;
  padding-top: 0;
  display: inline;
}
body.site.index header aside.login nav:before {
  content: "Admin: ";
}
body.site.index header aside.login nav a, body.site.index header aside.login nav a:link, body.site.index header aside.login nav a:visited {
  font-size: 0.9em;
  padding: 5px 0;
}
body.site.index header aside.login nav a:last-of-type, body.site.index header aside.login nav a:link:last-of-type, body.site.index header aside.login nav a:visited:last-of-type {
  font-size: 0.9em;
  text-transform: uppercase;
}
body.site.index header aside.login strong {
  font-style: italic;
}
body.site.index header aside.login a.logout {
  color: #fc9700;
  background: url("logout.png") no-repeat right top;
  padding-right: 25px;
  text-decoration: none;
}

div.flash {
  font-size: 1.4em;
  color: #fff;
  margin-bottom: 10px;
  padding: 1em 2em;
}
div.flash.notice {
  background: #fc9700;
}
div.flash.alert {
  background: red;
}
div.flash p {
  margin: 0;
}

/* WebKit browsers */
::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.5;
}

/* Mozilla Firefox 4 to 18 */
:-moz-placeholder {
  color: #fff;
  opacity: 0.5;
}

/* Mozilla Firefox 19+ */
::-moz-placeholder {
  color: #fff;
  opacity: 0.5;
}

/* Internet Explorer 10+ */
:-ms-input-placeholder {
  color: #fff;
  opacity: 0.5;
}

/* Modern Browsers */
::placeholder {
  color: #fff;
  opacity: 0.5;
}

#error_explanation {
  background: red;
  padding: 2em 3em;
  margin-bottom: 1em;
}
#error_explanation h2 {
  color: #fff;
  text-transform: none;
  margin: 0;
  padding: 0;
}
#error_explanation p {
  margin: 0;
  color: #fff;
}
#error_explanation ul {
  margin-bottom: 0;
}
#error_explanation li {
  color: #fff;
  font-size: 1.62em;
}

div.field_with_errors {
  display: inline;
}
div.field_with_errors label {
  color: red !important;
}

#content {
  background: #fff;
  margin-bottom: 10px;
  padding: 30px 0;
}

body.sessions #content, body.password_resets #content {
  padding: 30px;
}
body.sessions #content h1, body.password_resets #content h1 {
  color: #fc9700;
  text-align: center;
  margin: 0;
  padding: 0;
}
body.sessions #content div.field, body.sessions #content div.actions, body.password_resets #content div.field, body.password_resets #content div.actions {
  width: 400px;
  margin: 0 auto;
  margin-bottom: 10px;
}
body.sessions #content div.actions:after, body.password_resets #content div.actions:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
body.sessions #content input[type=text], body.sessions #content input[type=email], body.sessions #content input[type=password], body.password_resets #content input[type=text], body.password_resets #content input[type=email], body.password_resets #content input[type=password] {
  font-size: 1.62em;
  border: none;
  border-bottom: 5px solid #ffaa00;
  background: #fc9700;
  color: #fff;
  padding: 0.9em;
  width: 100%;
  box-sizing: border-box;
}
body.sessions #content input[type=submit], body.password_resets #content input[type=submit] {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #fc9700;
  color: #fff;
  padding: 0.4em 0.6em;
  font-size: 1.62em;
}
body.sessions #content a, body.sessions #content a:link, body.sessions #content a:visited, body.sessions #content a:hover, body.sessions #content a:active, body.password_resets #content a, body.password_resets #content a:link, body.password_resets #content a:visited, body.password_resets #content a:hover, body.password_resets #content a:active {
  font-size: 1.62em;
}

body.team_members.index #content {
  background: none;
  padding: 0;
}
body.team_members.index #content img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
body.team_members.index #content a, body.team_members.index #content a:link, body.team_members.index #content a:visited {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  background: rgba(252, 151, 0, 0.8);
  color: #fff;
  -moz-transition: opacity 1s;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
body.team_members.index #content a h2, body.team_members.index #content a h3, body.team_members.index #content a:link h2, body.team_members.index #content a:link h3, body.team_members.index #content a:visited h2, body.team_members.index #content a:visited h3 {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
}
body.team_members.index #content a h2, body.team_members.index #content a:link h2, body.team_members.index #content a:visited h2 {
  margin-top: 45%;
  font-size: 2em;
}
body.team_members.index #content a h3, body.team_members.index #content a:link h3, body.team_members.index #content a:visited h3 {
  font-size: 1.3em;
}
body.team_members.index #content a span, body.team_members.index #content a:link span, body.team_members.index #content a:visited span {
  font-size: 1.44em;
  display: block;
  position: absolute;
  top: 75%;
  left: 50%;
  margin-left: -4.5em;
  background: url("team_more.png") no-repeat right center;
  background-size: 1.1em 1.15em;
  height: 2.3em;
  line-height: 2.6em;
  padding-right: 1.5em;
}
body.team_members.index #content a:hover, body.team_members.index #content a:active, body.team_members.index #content a:link:hover, body.team_members.index #content a:link:active, body.team_members.index #content a:visited:hover, body.team_members.index #content a:visited:active {
  opacity: 1;
}

body.team_members.show #content {
  position: relative;
  padding: 0;
  font-size: 0.69444vw;
}
body.team_members.show #content section {
  display: block;
  box-sizing: border-box;
  height: 52vw;
  margin: -10px 0 0 0;
  padding: 13.5% 17% 0 56%;
  background-repeat: no-repeat;
  background-size: cover;
}
body.team_members.show #content h1, body.team_members.show #content h2 {
  margin: 0;
  padding: 0;
}
body.team_members.show #content h1 {
  font-size: 2.5em;
}
body.team_members.show #content h2 {
  font-size: 1.6em;
  color: #3c4547;
  text-transform: none;
  margin-bottom: 1em;
}
body.team_members.show #content p.email {
  float: left;
  display: block;
  height: 1.625em;
  line-height: 1.625em;
  background: url("email_bg_orange.svg") no-repeat left center;
  background-size: 1.6em 1.6em;
  padding-left: 2em;
}
body.team_members.show #content p.email a, body.team_members.show #content p.email a:link, body.team_members.show #content p.email a:visited {
  text-decoration: none;
}
body.team_members.show #content p.email a:hover, body.team_members.show #content p.email a:active, body.team_members.show #content p.email a:link:hover, body.team_members.show #content p.email a:link:active, body.team_members.show #content p.email a:visited:hover, body.team_members.show #content p.email a:visited:active {
  text-decoration: underline;
}
body.team_members.show #content p.tel {
  float: right;
  display: block;
  height: 1.625em;
  line-height: 1.625em;
  background: url("tel_bg_orange.svg") no-repeat left center;
  background-size: 1em 1.1em;
  padding-left: 1.25em;
}
body.team_members.show #content a.home {
  position: absolute;
  bottom: 4%;
  left: 50%;
  margin-left: -16px;
}
body.team_members.show #content a.home img {
  width: 3.2em;
  height: 3.2em;
}
body.team_members.show #content a.prev, body.team_members.show #content a.next {
  position: absolute;
  display: block;
  top: 0;
  width: 12%;
  height: 100%;
  box-sizing: border-box;
  font-size: 2em;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  color: #3c4547;
}
body.team_members.show #content a.prev span, body.team_members.show #content a.next span {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 25vw 10px 0 10px;
  box-sizing: border-box;
  background-size: cover;
}
body.team_members.show #content a.prev {
  left: -10px;
  background: url("minus.png") no-repeat center center;
  background-size: 2.5em 0.1em;
}
body.team_members.show #content a.prev span {
  left: -100%;
  transition: left 0.5s;
}
body.team_members.show #content a.prev:hover span, body.team_members.show #content a.prev:active span {
  left: 0;
}
body.team_members.show #content a.next {
  right: -10px;
  background: url("plus.png") no-repeat center center;
  background-size: 2.5em 2.5em;
}
body.team_members.show #content a.next span {
  right: -100%;
  transition: right 0.5s;
}
body.team_members.show #content a.next:hover span, body.team_members.show #content a.next:active span {
  right: 0;
}

body.site.apotheke_plus #content {
  background: none;
  padding: 0;
  margin-bottom: 0;
  position: relative;
  font-size: 0.69444vw;
}
body.site.apotheke_plus #content:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
body.site.apotheke_plus #content .slideshow {
  overflow: hidden;
  height: 51.5vw;
}
body.site.apotheke_plus #content .slide-container {
  width: 205%;
  position: relative;
}
body.site.apotheke_plus #content .slide {
  width: 50%;
  height: 51.1vw;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 -10px;
}
body.site.apotheke_plus #content .slide img {
  display: block;
  position: absolute;
  box-sizing: border-box;
  margin-bottom: 1em;
  margin-right: 1em;
}
body.site.apotheke_plus #content .slide img.quater {
  width: 24.28%;
}
body.site.apotheke_plus #content .slide img.half {
  width: 49.25%;
}
body.site.apotheke_plus #content .slide.pos_1 img:nth-of-type(1) {
  top: 0;
  left: 0;
}
body.site.apotheke_plus #content .slide.pos_1 img:nth-of-type(2) {
  top: 0;
  left: 25%;
}
body.site.apotheke_plus #content .slide.pos_1 img:nth-of-type(3) {
  top: 0;
  right: 0;
}
body.site.apotheke_plus #content .slide.pos_1 img:nth-of-type(4) {
  bottom: 0;
  left: 25%;
}
body.site.apotheke_plus #content .slide.pos_1 img:nth-of-type(5) {
  bottom: 0;
  left: 50%;
}
body.site.apotheke_plus #content .slide.pos_1 img:nth-of-type(6) {
  bottom: 0;
  right: 0;
}
body.site.apotheke_plus #content .slide.pos_2 img:nth-of-type(1) {
  top: 0;
  left: 0;
}
body.site.apotheke_plus #content .slide.pos_2 img:nth-of-type(2) {
  top: 0;
  left: 25%;
}
body.site.apotheke_plus #content .slide.pos_2 img:nth-of-type(3) {
  top: 0;
  right: 0;
}
body.site.apotheke_plus #content .slide.pos_2 img:nth-of-type(4) {
  bottom: 0;
  left: 0;
}
body.site.apotheke_plus #content .slide.pos_2 img:nth-of-type(5) {
  bottom: 0;
  left: 25%;
}
body.site.apotheke_plus #content .slide.pos_2 img:nth-of-type(6) {
  bottom: 0;
  left: 50%;
}
body.site.apotheke_plus #content nav a, body.site.apotheke_plus #content nav a:link, body.site.apotheke_plus #content nav a:visited {
  position: absolute;
  top: 22.1vw;
  display: block;
  line-height: 1em;
  background: #fc9700;
  padding: 2.2em 3em;
}
body.site.apotheke_plus #content nav a.prev, body.site.apotheke_plus #content nav a:link.prev, body.site.apotheke_plus #content nav a:visited.prev {
  left: 2em;
}
body.site.apotheke_plus #content nav a.next, body.site.apotheke_plus #content nav a:link.next, body.site.apotheke_plus #content nav a:visited.next {
  right: 2em;
}
body.site.apotheke_plus #content nav a.disabled, body.site.apotheke_plus #content nav a:link.disabled, body.site.apotheke_plus #content nav a:visited.disabled {
  background: #cbcbcb;
}
body.site.apotheke_plus #content nav a img, body.site.apotheke_plus #content nav a:link img, body.site.apotheke_plus #content nav a:visited img {
  width: 1.8em;
  height: 3.4em;
}

body.site.kontakt #content {
  display: flex;
  align-items: stretch;
  font-size: 0.69444vw;
  padding: 0;
}
body.site.kontakt #content h1 {
  margin: 0;
}
body.site.kontakt #content h2 {
  font-size: 2.2em;
  text-transform: none;
  color: #3c4547;
  margin: 0;
  padding: 0;
}
body.site.kontakt #content p {
  margin: 0;
}
body.site.kontakt #content p:last-of-type:after {
  display: none;
}
body.site.kontakt #content p:after, body.site.kontakt #content table:after {
  content: "–";
  display: block;
  color: #cbcbcb;
  font-size: 1.5em;
  line-height: 1.5em;
}
body.site.kontakt #content table:after {
  font-size: 2.43em;
  line-height: 1em;
}
body.site.kontakt #content address {
  padding: 3em 3em 3em 3.5em;
  width: 20.5vw;
  font-style: normal;
}
body.site.kontakt #content hr {
  margin: 2em 0;
  color: #cbcbcb;
  background: #cbcbcb;
}
body.site.kontakt #content p.contact-form {
  padding: 0 0 0 6.2em;
  background-image: url("contactform_bg.svg"), url("link_arrow.png");
  background-size: 5.3086em 3.6419em, 0.432em 0.7407em;
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right center;
}
body.site.kontakt #content p.contact-form a {
  font-size: 1.4em;
}
body.site.kontakt #content #map {
  flex-grow: 1;
  position: relative;
}
body.site.kontakt #content #map img, body.site.kontakt #content #map .copyright {
  display: block;
  position: absolute;
}
body.site.kontakt #content #map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
body.site.kontakt #content #map .copyright {
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: 0.2em 0.5em;
}

body.site.kontakt_form #content, body.site.kontakt_form_send #content {
  background: #fc9700;
}
body.site.kontakt_form #content:after, body.site.kontakt_form_send #content:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
body.site.kontakt_form #content div.center, body.site.kontakt_form_send #content div.center {
  width: 990px;
  margin: 0 auto;
}
body.site.kontakt_form #content h1, body.site.kontakt_form_send #content h1 {
  color: #fff;
  font-size: 2.2em;
  text-transform: uppercase;
  padding: 20px 0 20px 90px;
  background: url("contactform_bg_white.svg") no-repeat left center;
}
body.site.kontakt_form #content div.left, body.site.kontakt_form_send #content div.left {
  float: left;
  width: 34%;
}
body.site.kontakt_form #content div.right, body.site.kontakt_form_send #content div.right {
  float: right;
  width: 49%;
}
body.site.kontakt_form #content div.field, body.site.kontakt_form_send #content div.field {
  margin-bottom: 1em;
}
body.site.kontakt_form #content div.botr, body.site.kontakt_form_send #content div.botr {
  display: none;
}
body.site.kontakt_form #content label, body.site.kontakt_form_send #content label {
  color: #fff;
  font-size: 1.62em;
}
body.site.kontakt_form #content input[type=text], body.site.kontakt_form #content input[type=email], body.site.kontakt_form #content textarea, body.site.kontakt_form_send #content input[type=text], body.site.kontakt_form_send #content input[type=email], body.site.kontakt_form_send #content textarea {
  font-size: 1.62em;
  border: none;
  border-bottom: 2px solid #fa7900;
  background: #fb8700;
  color: #fff;
  padding: 0.9em;
}
body.site.kontakt_form #content input[type=text], body.site.kontakt_form #content input[type=email], body.site.kontakt_form #content textarea, body.site.kontakt_form_send #content input[type=text], body.site.kontakt_form_send #content input[type=email], body.site.kontakt_form_send #content textarea {
  width: 100%;
  box-sizing: border-box;
}
body.site.kontakt_form #content textarea, body.site.kontakt_form_send #content textarea {
  font-family: BrandonText-Regular;
  height: 12.5vw;
}
body.site.kontakt_form #content input[type=submit], body.site.kontakt_form_send #content input[type=submit] {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #fff;
  background: #fb8700;
  color: #fff;
  padding: 0.4em 0.6em;
  font-size: 1.62em;
  cursor: pointer;
}
body.site.kontakt_form #content .terms-of-service, body.site.kontakt_form_send #content .terms-of-service {
  font-size: 0.9em;
}
body.site.kontakt_form #content .terms-of-service input, body.site.kontakt_form_send #content .terms-of-service input {
  display: inline-block;
  vertical-align: top;
}
body.site.kontakt_form #content .terms-of-service label, body.site.kontakt_form_send #content .terms-of-service label {
  display: inline-block;
  width: 90%;
}
body.site.kontakt_form #content .terms-of-service p, body.site.kontakt_form_send #content .terms-of-service p {
  margin-left: 22px;
  color: #fff;
}
body.site.kontakt_form #content .terms-of-service a, body.site.kontakt_form #content .terms-of-service a:link, body.site.kontakt_form #content .terms-of-service a:hover, body.site.kontakt_form #content .terms-of-service a:visited, body.site.kontakt_form #content .terms-of-service a:active, body.site.kontakt_form_send #content .terms-of-service a, body.site.kontakt_form_send #content .terms-of-service a:link, body.site.kontakt_form_send #content .terms-of-service a:hover, body.site.kontakt_form_send #content .terms-of-service a:visited, body.site.kontakt_form_send #content .terms-of-service a:active {
  color: #fff;
  padding: 0;
  font-size: 1em;
  margin-right: 0;
}
body.site.order_form #content, body.site.order_form_send #content {
  background: url("order_form.jpg") no-repeat right center #fff;
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
  /* Modern Browsers */
}
body.site.order_form #content:after, body.site.order_form_send #content:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
body.site.order_form #content div.center, body.site.order_form_send #content div.center {
  width: 990px;
  margin: 0 auto;
}
body.site.order_form #content h1, body.site.order_form_send #content h1 {
  color: #fc9700;
  font-size: 2.8em;
}
body.site.order_form #content div.left, body.site.order_form_send #content div.left {
  float: left;
  width: 30%;
  position: relative;
}
body.site.order_form #content div.right, body.site.order_form_send #content div.right {
  width: 30%;
  margin-left: 35%;
}
body.site.order_form #content div.field, body.site.order_form_send #content div.field {
  margin-bottom: 1em;
}
body.site.order_form #content div.field.inline input, body.site.order_form_send #content div.field.inline input {
  display: inline-block;
  width: auto;
}
body.site.order_form #content div.field.inline #orderform_plz, body.site.order_form_send #content div.field.inline #orderform_plz {
  width: 30%;
}
body.site.order_form #content div.field.inline #orderform_ort, body.site.order_form_send #content div.field.inline #orderform_ort {
  width: 69%;
}
body.site.order_form #content div.actions, body.site.order_form_send #content div.actions {
  position: absolute;
  bottom: 1em;
  right: -54%;
}
body.site.order_form #content div.botr, body.site.order_form_send #content div.botr {
  display: none;
}
body.site.order_form #content label, body.site.order_form_send #content label {
  color: #3c4547;
  display: block;
  margin-left: 2em;
  font-size: 1.62em;
}
body.site.order_form #content input[type=checkbox], body.site.order_form_send #content input[type=checkbox] {
  float: left;
}
body.site.order_form #content input[type=text], body.site.order_form_send #content input[type=text] {
  font-size: 1.62em;
  border: none;
  border: 1px solid #adadad;
  background: #fff;
  color: #adadad;
  color: #3c4547;
  padding: 0.9em;
  width: 100%;
  box-sizing: border-box;
}
body.site.order_form #content ::-webkit-input-placeholder, body.site.order_form_send #content ::-webkit-input-placeholder {
  color: #3c4547;
}
body.site.order_form #content :-moz-placeholder, body.site.order_form_send #content :-moz-placeholder {
  color: #3c4547;
}
body.site.order_form #content ::-moz-placeholder, body.site.order_form_send #content ::-moz-placeholder {
  color: #3c4547;
}
body.site.order_form #content :-ms-input-placeholder, body.site.order_form_send #content :-ms-input-placeholder {
  color: #3c4547;
}
body.site.order_form #content ::placeholder, body.site.order_form_send #content ::placeholder {
  color: #3c4547;
}
body.site.order_form #content input[type=submit], body.site.order_form_send #content input[type=submit] {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #000;
  cursor: pointer;
  font-size: 1.62em;
  height: 40px;
  line-height: 36px;
  padding: 0 35px 0 10px;
  text-decoration: none;
  background: url("order-form-arrow.svg") no-repeat right center;
}

section.overlay {
  position: fixed;
  overflow-y: scroll;
  z-index: 1000;
}
section.overlay.start {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0.88em;
  background: #fff;
  background: rgba(255, 255, 255, 0.95);
}
section.overlay.start.show {
  opacity: 1;
}
section.overlay.start img.logo {
  display: block;
  margin: 0 auto;
  margin-top: 107px;
}
section.overlay div.content {
  width: 588px;
  margin: 0 auto;
  margin-top: 65px;
  padding-left: 12px;
}
section.overlay address {
  display: block;
  font-style: normal;
  float: left;
  width: 240px;
}
section.overlay address p, section.overlay address table {
  padding: 0 0 20px 35px;
  margin: 0;
  line-height: 1.5em;
}
section.overlay address p.tel, section.overlay address table.tel {
  background: url("tel_bg_gray.svg") no-repeat 0 0;
}
section.overlay address p.open, section.overlay address table.open {
  background: url("open_bg_gray.svg") no-repeat 0 0;
  padding-bottom: 0;
}
section.overlay address p.address, section.overlay address table.address {
  background: url("address_bg_gray.svg") no-repeat 0 0;
}
section.overlay aside {
  display: block;
  padding-left: 95px;
  margin-left: 275px;
}
section.overlay aside.night-service {
  display: block;
  background: url("nachtdienst.svg") no-repeat 0 5px;
  padding-bottom: 25px;
  padding-top: 0;
  border-bottom: 1px solid #cbcbcb;
}
section.overlay aside.night-service h2 {
  font-size: 2.1em;
  padding: 0;
}
section.overlay aside.night-service p {
  margin: 5px 0;
}
section.overlay aside.night-service a, section.overlay aside.night-service a:link, section.overlay aside.night-service a:visited {
  display: inline-block;
  background: #fc9700;
  color: #fff;
  padding: 0.4em 0.8em;
  font-size: 1.62em;
  text-decoration: none;
}
section.overlay aside.app {
  background: url("apo-app_bg.png") no-repeat 0 15px;
}
section.overlay aside.app h3 {
  color: #3c4547;
  font-size: 2.1em;
  padding: 14px 0 0 0;
  margin-bottom: 0;
}
section.overlay aside.app p {
  margin: 0 0 5px 0;
}
section.overlay aside.app a.apple {
  margin-right: 10px;
}
section.overlay a.close {
  position: absolute;
  top: 60px;
  right: 55px;
}

section.container {
  background-color: #fff;
  display: block;
  float: left;
  position: relative;
  z-index: 1;
  margin: 0 -10px 10px -10px;
  border-left: 10px solid #f2f2f2;
  border-right: 10px solid #f2f2f2;
  font-size: 0.69444vw;
}
section.container a, section.container a:link, section.container a:visited, section.container a:hover, section.container a:active {
  text-decoration: none;
}
section.container a.full {
  display: block;
  width: 100%;
  height: 100%;
}
section.container p {
  padding-left: 4.3055vw;
}
section.container p.open {
  background: url("open_bg.svg") no-repeat 1.3888vw 0;
  background-size: 1.3194vw 1.3194vw;
}
section.container p.tel {
  background: url("tel_bg.svg") no-repeat 1.3888vw 0;
  background-size: 0.9722vw 1.1805vw;
}
section.container p.fax {
  background: url("fax_bg.svg") no-repeat 1.3888vw 0;
  background-size: 1.3888vw 1.3888vw;
}
section.container p.email {
  background: url("email_bg.svg") no-repeat 1.3888vw 0;
  background-size: 1.3194vw 1.3194vw;
}
section.container p.address {
  background: url("address_bg.svg") no-repeat 1.3888vw 0;
  background-size: 1.0416vw 1.5972vw;
}
section.container.full {
  width: 100%;
}
section.container.half {
  width: 50%;
  height: 25vw;
}
section.container.half img {
  float: right;
  width: 25vw;
}
section.container.quater {
  width: 25%;
  height: 25vw;
}
section.container.orange {
  background-color: #fc9700;
  color: #fff;
}
section.container.orange h2 {
  color: #fff;
}
section.container.orange a, section.container.orange a:link, section.container.orange a:visited {
  color: #fff;
}
section.container.orange a:hover, section.container.orange a:active, section.container.orange a.active, section.container.orange a:link:hover, section.container.orange a:link:active, section.container.orange a:link.active, section.container.orange a:visited:hover, section.container.orange a:visited:active, section.container.orange a:visited.active {
  color: #fff;
}
section.container.with-icon {
  background-position: 1.7vw 19.1vw;
  background-repeat: no-repeat;
}
section.container.plus-des-monats {
  background-position: 19.1vw 19.1vw;
  background-image: url("das_plus_des_monats_bg.svg");
  background-size: 3.8194vw 3.8194vw;
}
section.container.plus-des-monats img {
  height: auto;
  width: auto;
  max-height: 85%;
  max-width: 35%;
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto;
}
section.container.plus-des-monats div.prices {
  position: absolute;
  bottom: 1vw;
  left: 1.7vw;
}
section.container.plus-des-monats div.prices .from {
  font-size: 1.8em;
  display: block;
}
section.container.plus-des-monats div.prices .to {
  font-size: 3.2em;
}
section.container.plus-des-monats div.prices .to strong {
  font-size: 1.2em;
  font-weight: normal;
  color: #fc9700;
}
section.container.plus-des-monats a:hover, section.container.plus-des-monats a:active, section.container.plus-des-monats a.active {
  color: #3c4547;
}
section.container.apotheke {
  background-image: url("apotheke_bg.svg");
  background-size: 4.1666vw 4.1666vw;
}
section.container.apo-mobile {
  background-image: url("apo_mobile_bg.svg");
  background-position: 1.7vw 17.5vw;
  background-size: 5.8vw 5.8vw;
}
section.container.apo-mobile h2 {
  padding-bottom: 0;
}
section.container.apo-mobile h3 {
  color: #fff;
  text-transform: none;
  padding: 0 0.8em;
  line-height: 1.1em;
}
section.container.contactform {
  background-image: url("contactform_bg.svg");
  background-position: 27vw 19vw;
  background-size: 5.9722vw 4.0972vw;
}
section.container.contactform img {
  float: left;
  margin-top: 3.7vw;
  margin-right: 2.2vw;
}
section.container.contactform h2 {
  text-transform: none;
  padding: 0.95em 0.6em 0.36em 0.6em;
  line-height: 1.1em;
}
section.container.contactform a, section.container.contactform a:link, section.container.contactform a:visited {
  background: #fc9700;
  color: #fff;
  text-decoration: none;
  font-size: 1.44em;
  padding: 0.8em 2.1em 0.6em 0.8em;
  display: inline-block;
  position: relative;
  margin-top: 1em;
}
section.container.contactform a:after, section.container.contactform a:link:after, section.container.contactform a:visited:after {
  content: " ";
  display: block;
  background: url("button_arrow.svg") no-repeat #fc9700;
  background-size: 0.4861vw 1.0416vw;
  width: 0.4861vw;
  height: 1.0416vw;
  position: absolute;
  right: 0.6249vw;
  top: 0.8333vw;
}

section.centered-content {
  display: block;
  width: 960px;
  margin: 0 auto;
}
section.centered-content h2 {
  text-transform: none;
  margin: 0;
  padding: 0;
}
section.centered-content img {
  display: block;
  margin: 0 auto;
  margin-top: -20px;
}
section.centered-content h3, section.centered-content p {
  text-align: center;
  margin: 0;
  padding: 0;
}
section.centered-content p {
  width: 600px;
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
}
section.centered-content p:last-of-type {
  margin-bottom: 0;
}

section.newsletter-abo {
  display: block;
  width: 960px;
  margin: 0 auto;
}
section.newsletter-abo:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
section.newsletter-abo h1 {
  font-size: 2.2em;
  color: #fc9700;
  text-transform: none;
  margin: 0;
  margin-bottom: 1em;
}
section.newsletter-abo p {
  margin-bottom: 0;
}
section.newsletter-abo ul {
  margin-top: 0;
}
section.newsletter-abo li {
  font-size: 1.62em;
  font-weight: bold;
}
section.newsletter-abo a {
  padding: 0.5em 0.5em 0.3em 0.5em;
  font-size: 1.62em;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
}
section.newsletter-abo a.edit {
  background: #fc9700;
}
section.newsletter-abo a:hover, section.newsletter-abo a:active {
  color: #fff;
}
section.newsletter-abo form.button_to button.delete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #cbcbcb;
  cursor: pointer;
  width: auto;
  margin-top: 0.5em;
}
section.newsletter-abo label {
  font-size: 1.62em;
}
section.newsletter-abo input[type=text], section.newsletter-abo input[type=email] {
  font-size: 1.62em;
  border: none;
  border-bottom: 5px solid #ffaa00;
  background: #fc9700;
  color: #fff;
  padding: 0.9em;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1em;
}
section.newsletter-abo input[type=submit] {
  float: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #fc9700;
  color: #fff;
  padding: 0.4em 0.6em;
  font-size: 1.62em;
}
section.newsletter-abo input[type=checkbox] {
  margin-left: 20px;
}
section.newsletter-abo .terms-of-service {
  margin: 2em 0;
}
section.newsletter-abo .terms-of-service input {
  display: inline-block;
  vertical-align: top;
}
section.newsletter-abo .terms-of-service label {
  display: inline-block;
  width: 90%;
}
section.newsletter-abo .terms-of-service p {
  margin-left: 40px;
}
section.newsletter-abo .terms-of-service a, section.newsletter-abo .terms-of-service a:link, section.newsletter-abo .terms-of-service a:hover, section.newsletter-abo .terms-of-service a:visited, section.newsletter-abo .terms-of-service a:active {
  color: #fc9700;
  padding: 0;
  font-size: 1em;
  margin-right: 0;
}

section.plus-des-monats-show {
  position: relative;
}
section.plus-des-monats-show:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
section.plus-des-monats-show time {
  display: block;
  font-size: 2.8em;
  color: #3c4547;
  display: block;
  margin-bottom: 2.125em;
}
section.plus-des-monats-show img {
  display: block;
  margin: 0 0 0 6em;
  float: left;
}
section.plus-des-monats-show h3, section.plus-des-monats-show p {
  display: block;
  text-align: right;
  margin: 0 0 0.8em 480px;
  padding: 0;
}
section.plus-des-monats-show p {
  width: auto;
}
section.plus-des-monats-show p:last-of-type {
  padding-bottom: 1em;
  border-bottom: 1px solid #cbcbcb;
}
section.plus-des-monats-show div.prices {
  text-align: right;
  margin-top: 1em;
}
section.plus-des-monats-show div.prices .from {
  font-size: 1.8em;
  display: block;
}
section.plus-des-monats-show div.prices .to {
  font-size: 3.2em;
}
section.plus-des-monats-show div.prices .to strong {
  font-size: 1.2em;
  font-weight: normal;
  color: #fc9700;
}

section.philosophie {
  position: relative;
}
section.philosophie h2 {
  display: block;
  background: #fff;
  position: absolute;
  top: 250px;
  left: 50%;
  width: 175px;
  margin-left: -88px;
  padding: 0.5em 0;
  text-align: center;
}
section.philosophie img {
  margin-top: 30px;
  margin-bottom: 30px;
}

section.zustellungen {
  position: relative;
}
section.zustellungen:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
section.zustellungen h2 {
  margin-bottom: 2em;
}
section.zustellungen h3 {
  text-align: left;
  margin-bottom: 1em;
}
section.zustellungen p {
  margin: 0 0 1em 0;
  text-align: left;
  width: 100%;
}
section.zustellungen p.tel, section.zustellungen p.email {
  padding-left: 40px;
  font-size: 2.34em;
  color: #fc9700;
}
section.zustellungen p.tel a, section.zustellungen p.email a {
  color: #fc9700;
  text-decoration: none;
}
section.zustellungen p.tel {
  background: url("tel_bg_orange.svg") no-repeat left center;
  background-size: auto 22px;
}
section.zustellungen p.email {
  background: url("email_bg_orange.svg") no-repeat left center;
}
section.zustellungen section {
  display: block;
}
section.zustellungen section.info {
  float: left;
  width: 45%;
}
section.zustellungen section.contact {
  float: right;
  width: 45%;
}

section.apo_mobile {
  position: relative;
}
section.apo_mobile:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
section.apo_mobile h2 {
  margin-bottom: 1em;
}
section.apo_mobile img.phone {
  float: right;
  margin-top: 0.2em;
  margin-left: 100px;
}
section.apo_mobile h3 {
  text-align: left;
  margin-bottom: 1em;
}
section.apo_mobile p {
  margin: 0 0 1em 0;
  text-align: left;
  width: 100%;
}
section.apo_mobile div.app {
  background: url("apo_mobile_qr.svg") no-repeat 0 15px;
  padding-left: 115px;
  min-height: 87px;
  margin-top: 2em;
}
section.apo_mobile div.app h3 {
  color: #3c4547;
  font-size: 2.1em;
  padding: 14px 0 0 0;
  margin-bottom: 0;
}
section.apo_mobile div.app p {
  margin: 0 0 5px 0;
}
section.apo_mobile div.app img {
  display: inline;
  margin: 0;
}
section.apo_mobile div.app a.apple {
  margin-right: 10px;
}

section.impressum {
  position: fixed;
  overflow-y: scroll;
  z-index: 1000;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 13em;
  border: 10px solid #f2f2f2;
  background: #fc9700;
  color: #fff;
}
section.impressum h2 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #fff;
  display: block;
  position: absolute;
  top: 2em;
  left: 50%;
  width: 220px;
  margin-left: -110px;
  padding-bottom: 0.4em;
}
section.impressum h3 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #fff;
  display: block;
  width: 220px;
  padding-bottom: 0.4em;
  font-size: 2.88em;
  margin: 1em auto;
}
section.impressum p:after {
  content: "–";
  display: block;
}
section.impressum p:last-of-type:after {
  display: none;
}
section.impressum a, section.impressum a:link, section.impressum a:visited, section.impressum a:hover, section.impressum a:active {
  color: #fff;
}
section.impressum a.close {
  position: absolute;
  top: 60px;
  right: 55px;
}

h2 {
  font-weight: normal;
  font-style: normal;
  font-size: 2.88em;
  color: #fc9700;
  text-transform: uppercase;
  margin: 0;
  padding: 1em 0.695em;
}

h3 {
  color: #fc9700;
  font-family: BrandonText-Light;
  font-weight: normal;
  font-style: normal;
  font-size: 2.16em;
  padding: 0;
  margin: 0;
}

h4 {
  color: #3c4547;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

footer {
  display: block;
  clear: both;
  background: #3c4547;
  color: #fff;
  margin: 0 -10px;
  padding: 3em 2.5em;
  font-size: 0.69444vw;
}
footer:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
footer p, footer nav {
  margin: 0 3.0864em 0 0;
  display: block;
  float: left;
}
footer nav {
  font-size: 1.62em;
}
footer nav a, footer nav a:link, footer nav a:visited, footer nav a:hover, footer nav a:active {
  text-decoration: none;
  font-weight: bold;
  font-style: italic;
}
footer div.newsletter {
  display: block;
  float: left;
  font-size: 1.62em;
  width: 19.44vw;
}
footer div.newsletter h4 {
  font-size: 1em;
  font-weight: bold;
  color: #fff;
}
footer div.newsletter p {
  margin: 0;
  padding: 0;
  float: none;
  font-size: 0.85em;
}
footer div.newsletter a, footer div.newsletter a:link, footer div.newsletter a:visited {
  display: block;
  padding: 0.6em 1.5em 0.5em 1.5em;
  margin-top: 0.5em;
  background: #000;
  text-decoration: none;
  position: relative;
}
footer div.newsletter a:hover, footer div.newsletter a:active, footer div.newsletter a:link:hover, footer div.newsletter a:link:active, footer div.newsletter a:visited:hover, footer div.newsletter a:visited:active {
  background: rgb(25.5, 25.5, 25.5);
}
footer div.newsletter a:after, footer div.newsletter a:link:after, footer div.newsletter a:visited:after {
  content: " ";
  display: block;
  background: url("button_arrow2.svg") no-repeat;
  background-size: 95% 95%;
  background-position: center center;
  width: 1.25em;
  height: 1.25em;
  position: absolute;
  right: 0.6em;
  top: 0.6em;
}
footer img {
  float: left;
  width: 9vw;
  margin-right: 4.514vw;
}
footer a, footer a:link, footer a:visited {
  color: #fff;
}
footer a:hover, footer a:active, footer a:link:hover, footer a:link:active, footer a:visited:hover, footer a:visited:active {
  color: #fff;
}
footer a.app, footer a:link.app, footer a:visited.app {
  text-decoration: none;
}

.flash.notice .dsgvo {
  width: 768px;
  margin: 120px auto;
  text-align: center;
  font-size: 23px;
  line-height: 32px;
}

body.newsletters.show section.newsletter {
  display: block;
  width: 630px;
  margin: 0 auto;
}
body.newsletters.show section.newsletter section.news {
  display: block;
  margin-bottom: 4em;
  margin-right: -10px;
}
body.newsletters.show section.newsletter h2 {
  text-transform: none;
  margin: 0;
  padding: 0;
}
body.newsletters.show section.newsletter hr {
  background-color: #cbcbcb;
  margin: 3em 0;
}
body.newsletters.show section.newsletter img {
  display: block;
  float: left;
  margin-right: 10px;
}
body.newsletters.show section.newsletter h3, body.newsletters.show section.newsletter p {
  text-align: left;
  margin: 0 0 1em 0;
  padding: 0;
}
body.newsletters.show section.newsletter h4 {
  clear: left;
  font-size: 2.4em;
  text-transform: none;
  margin: 0.5em 0;
  padding-top: 1em;
}
body.newsletters.show section.newsletter p {
  width: 630px;
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
}
body.newsletters.show section.newsletter p:last-of-type {
  margin-bottom: 0;
}
body.newsletters.show section.newsletter a.more {
  font-size: 1.8em;
  text-decoration: none;
  display: block;
  width: 100px;
  text-align: center;
  margin: 1em auto;
  background: url("newsletter_link.png") no-repeat top center;
  padding-top: 66px;
}
body.newsletters.show section.newsletter div.actions {
  font-size: 1.62em;
}

/* Media Query für Desktop */
@media (min-width: 1024px) {
  .menu-button {
    display: none;
  }
  .mobile-menu {
    position: static;
    transform: none;
    display: block;
  }
}
@media screen and (max-width: 1023px) {
  [data-controller=mobile-menu].is-open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
  }
  [data-controller=mobile-menu].is-open .mobile-menu {
    display: block;
    transform: translateX(0);
  }
  .mobile-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 14px;
    background: white;
    padding-top: 48px !important;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  }
  .mobile-menu a {
    display: block;
    line-height: 28px;
    font-size: 1.2em !important;
    padding: 0 0 10px 0 !important;
    margin-top: 12px;
    border-bottom: 1px solid #cbcbcb;
  }
  .mobile-menu a.active {
    border-bottom: 2px solid #fc9700;
    color: #fc9700;
  }
  /* Hamburger Button Styling */
  .menu-button {
    color: #fc9700;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 100;
    appearance: none;
    -webkit-appearance: none;
    background: url("burger.svg") no-repeat center;
    background-size: contain;
    border: none;
    width: 34px;
    height: 34px;
    padding: 0;
    cursor: pointer;
    text-indent: -9999px;
    overflow: hidden;
  }
  body {
    box-sizing: border-box;
  }
  header, body.site.index header, body.site.index header.scroll {
    text-align: left;
    height: 48px;
    padding-top: 0;
  }
  header div.content, body.site.index header div.content, body.site.index header.scroll div.content {
    position: fixed !important;
    width: 100%;
    height: 48px;
  }
  header div.content h1, body.site.index header div.content h1, body.site.index header.scroll div.content h1 {
    width: 90px;
    height: 48px;
    line-height: 48px;
    background: url("apotheke-oberalm_logo_header_scroll.svg") no-repeat;
    background-size: 90px 23px;
    background-position: 0 center;
    margin-left: 0;
    left: 10px;
    top: 0;
    padding: 0;
    border-bottom: none;
  }
  header div.content nav, body.site.index header div.content nav, body.site.index header.scroll div.content nav {
    margin-left: 0;
  }
  header div.content nav a, header div.content nav a:link, header div.content nav a:visited, body.site.index header div.content nav a, body.site.index header div.content nav a:link, body.site.index header div.content nav a:visited, body.site.index header.scroll div.content nav a, body.site.index header.scroll div.content nav a:link, body.site.index header.scroll div.content nav a:visited {
    display: block;
    line-height: 28px;
    font-size: 1.2em !important;
    padding: 0 0 10px 0 !important;
    margin-top: 12px;
  }
  header a.info, header a.info:visited, header a.info:hover, header a.info:active, body.site.index header a.info, body.site.index header a.info:visited, body.site.index header a.info:hover, body.site.index header a.info:active, body.site.index header.scroll a.info, body.site.index header.scroll a.info:visited, body.site.index header.scroll a.info:hover, body.site.index header.scroll a.info:active {
    position: static;
    color: #3c4547;
    background: none;
    font-size: 1.8em;
    font-family: BrandonText-Regular;
    height: auto;
    line-height: 25px;
    width: auto;
    border-radius: 0;
    text-align: left;
    border-bottom: 2px solid #fc9700 !important;
    text-transform: uppercase !important;
  }
  header a.info:after, header a.info:visited:after, header a.info:hover:after, header a.info:active:after, body.site.index header a.info:after, body.site.index header a.info:visited:after, body.site.index header a.info:hover:after, body.site.index header a.info:active:after, body.site.index header.scroll a.info:after, body.site.index header.scroll a.info:visited:after, body.site.index header.scroll a.info:hover:after, body.site.index header.scroll a.info:active:after {
    content: "NFO";
  }
  #error_explanation h2 {
    font-size: 2.4em;
    margin-bottom: 0.5em;
  }
  body.team_members.show #content {
    height: auto;
  }
  body.team_members.show #content section {
    height: auto;
    font-size: 4em;
    margin: 0;
    padding: 75vw 20px 80px 20px;
    background-size: auto 70vw;
  }
  body.team_members.show #content p.email {
    float: none;
  }
  body.team_members.show #content p.tel {
    float: none;
  }
  body.team_members.show #content a.prev, body.team_members.show #content a.next {
    display: none;
  }
  body.site.apotheke_plus #content .slideshow {
    height: 100vw;
    margin-bottom: 10px;
  }
  body.site.apotheke_plus #content .slide-container {
    width: 100%;
  }
  body.site.apotheke_plus #content .slide-container img {
    position: static;
    margin-bottom: 0;
    margin-right: 0;
  }
  body.site.apotheke_plus #content .slide-container img.quater {
    width: 100%;
  }
  body.site.apotheke_plus #content .slide-container img.doubleheight {
    height: 100vw;
    width: auto;
    margin: 0 auto;
  }
  body.site.apotheke_plus #content .slide-container img.half {
    width: 100%;
    margin-top: 25%;
  }
  body.site.apotheke_plus #content nav {
    display: none;
  }
  div.swipe-info {
    background: url("swipe.png") no-repeat center center;
    background-size: contain;
    position: absolute;
    width: 94vw;
    height: 20vw;
    top: 40%;
    display: none;
  }
  body.site.kontakt #content {
    font-size: 2.7777vw;
    display: block;
  }
  body.site.kontakt #content address {
    width: auto;
  }
  body.site.kontakt #content hr {
    width: 80%;
  }
  body.site.kontakt #content p.contact-form {
    padding: 0 0 0 6.2em;
    background-image: url("contactform_bg.svg");
    background-size: 5.3086em 3.6419em;
    background-repeat: no-repeat;
    background-position: left top;
  }
  body.site.kontakt #content #map {
    margin-left: 0;
    height: 100vw;
  }
  body.site.kontakt_form #content div.center, body.site.kontakt_form_send #content div.center {
    width: auto;
    margin: 0 20px;
  }
  body.site.kontakt_form #content h1, body.site.kontakt_form_send #content h1 {
    margin-top: -10px;
    font-size: 5.8vw;
  }
  body.site.kontakt_form #content div.left, body.site.kontakt_form_send #content div.left {
    float: none;
    width: 100%;
  }
  body.site.kontakt_form #content div.right, body.site.kontakt_form_send #content div.right {
    float: none;
    width: 100%;
  }
  body.site.kontakt_form #content input[type=text], body.site.kontakt_form #content input[type=email], body.site.kontakt_form #content textarea, body.site.kontakt_form_send #content input[type=text], body.site.kontakt_form_send #content input[type=email], body.site.kontakt_form_send #content textarea {
    box-sizing: border-box;
    width: 100%;
  }
  body.site.kontakt_form #content textarea, body.site.kontakt_form_send #content textarea {
    height: 25vw;
  }
  body.site.order_form #content, body.site.order_form_send #content {
    background: #fff;
  }
  body.site.order_form #content div.center, body.site.order_form_send #content div.center {
    width: auto;
  }
  body.site.order_form #content h1, body.site.order_form_send #content h1 {
    font-size: 7.6vw;
    margin-top: 0;
    margin-left: 20px;
  }
  body.site.order_form #content div.left, body.site.order_form_send #content div.left {
    float: none;
    width: auto;
    padding: 0 20px;
  }
  body.site.order_form #content div.right, body.site.order_form_send #content div.right {
    width: auto;
    margin-left: 0;
    padding: 0 20px;
  }
  body.site.order_form #content div.actions, body.site.order_form_send #content div.actions {
    position: static;
  }
  body.site.order_form #content input[type=submit], body.site.order_form_send #content input[type=submit] {
    float: none;
  }
  body.news.show #content, body.news_categories.show #content {
    font-size: 2.7777vw;
    padding-top: 0px;
  }
  body.news.show #content nav.category, body.news_categories.show #content nav.category {
    width: 100%;
    height: auto;
    position: static;
    padding: 0;
    margin-top: 0 !important;
    background-image: url("news_nav_mobile.svg");
    background-repeat: no-repeat;
    background-size: 22px 10px;
    background-position: right 18px center;
  }
  body.news.show #content nav.category a, body.news_categories.show #content nav.category a {
    display: none !important;
    padding: 0;
    font-size: 2em !important;
    line-height: 3em !important;
    margin: 0 !important;
  }
  body.news.show #content nav.category a.active, body.news_categories.show #content nav.category a.active {
    display: block !important;
    border-bottom: none !important;
  }
  body.news.show #content nav.category span.close, body.news_categories.show #content nav.category span.close {
    display: none;
  }
  body.news.show #content nav.category.open, body.news_categories.show #content nav.category.open {
    position: relative;
    background-image: none;
    width: 86%;
  }
  body.news.show #content nav.category.open a, body.news_categories.show #content nav.category.open a {
    display: block !important;
  }
  body.news.show #content nav.category.open a.active, body.news_categories.show #content nav.category.open a.active {
    background: #fc9700;
    color: #fff;
  }
  body.news.show #content nav.category.open span.close, body.news_categories.show #content nav.category.open span.close {
    position: absolute;
    display: block;
    top: 0;
    right: -16%;
    width: 16%;
    height: 15%;
    background: #fff;
    background-image: url("close_gray.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
  }
  body.news.show #content article.quater, body.news_categories.show #content article.quater {
    width: 100%;
    height: 72vw;
  }
  body.news.show #content article.news, body.news_categories.show #content article.news {
    background-position: right 2.5vw bottom 2.5vw;
    background-size: 5vw 5vw;
  }
  body.news.show #content article.news img, body.news_categories.show #content article.news img {
    width: 100%;
  }
  body.news.show #content div.gap, body.news_categories.show #content div.gap {
    display: none !important;
  }
  body.news.show #content div.pictures, body.news_categories.show #content div.pictures {
    min-height: 100px;
    max-width: 100%;
    width: auto;
  }
  body.news.show #content div.pictures img, body.news_categories.show #content div.pictures img {
    width: 100%;
  }
  body.news.show #content h1, body.news_categories.show #content h1 {
    width: auto;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: left;
  }
  body.news.show #content p, body.news_categories.show #content p {
    width: auto;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  body.news.show #content a.close-news, body.news_categories.show #content a.close-news {
    display: none;
  }
  body.team_members.index #content a:hover, body.team_members.index #content a:active {
    opacity: 0 !important;
  }
  section.overlay.start img.logo {
    margin-top: 20px;
    width: 50%;
  }
  section.overlay div.content {
    width: auto;
    margin-top: 15px;
  }
  section.overlay address {
    float: none;
    width: auto;
    margin-left: 20px;
    font-size: 1.2em;
  }
  section.overlay aside {
    padding-left: 95px;
    margin-left: 20px;
  }
  section.overlay a.close {
    top: 20px;
    right: 15px;
  }
  section.container p {
    padding-left: 17.222vw;
    background-position: 5.5552vw 0 !important;
  }
  section.container p.open {
    background-size: 5.2776vw 5.2776vw;
  }
  section.container p.tel {
    background-size: 3.8888vw 4.722vw;
    background-position: 5.5552vw 3vw !important;
  }
  section.container p.fax {
    background-size: 5.5552vw 5.3vw;
    background-position: 5.5552vw 3vw !important;
  }
  section.container p.email {
    background-size: 5.2776vw 5.2776vw;
    background-position: 5.5552vw 3vw !important;
  }
  section.container p.address {
    background-size: 4.1664vw 6.3888vw;
  }
  section.container h2 {
    padding-top: 0.8em;
  }
  section.container.half {
    width: 100%;
    height: 100vw;
  }
  section.container.half img {
    float: none;
    width: 100%;
    height: auto;
  }
  section.container.half h2 {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 11.52em;
  }
  section.container.quater {
    width: 100%;
    height: 97vw;
  }
  section.container.quater h2 {
    font-size: 11.52em;
  }
  section.container.quater h3 {
    font-size: 10em;
  }
  section.container.quater p {
    font-size: 7.4em;
    line-height: 1.3em;
  }
  section.container.gap {
    display: none;
  }
  section.container.with-icon {
    background-position: 3.4vw 87vw;
  }
  section.container.plus-des-monats {
    background-size: 7.6388vw 7.6388vw;
    background-position: 80vw 9vw;
    position: relative;
    height: 120vw;
  }
  section.container.plus-des-monats h2 {
    width: 60vw;
  }
  section.container.plus-des-monats img {
    position: absolute;
    top: 20vw;
    left: 0;
    width: auto;
    max-width: 95%;
    max-height: 55vw;
  }
  section.container.plus-des-monats div.prices {
    bottom: 3vw;
    left: 10vw;
  }
  section.container.plus-des-monats div.prices .from {
    font-size: 6em;
  }
  section.container.plus-des-monats div.prices .to {
    font-size: 10em;
  }
  section.container.apotheke {
    height: auto;
    background-size: 7.6388vw 7.6388vw;
    background-position: 80vw 9vw;
  }
  section.container.apotheke img {
    margin-top: 25vw;
  }
  section.container.apo-mobile {
    background-position: 5vw 72vw;
    background-size: 20vw 20vw;
  }
  section.container.apo-mobile h2 {
    padding-top: 1em;
  }
  section.container.contact {
    height: 100vw;
  }
  section.container.contact h2 {
    padding-bottom: 0;
  }
  section.container.contact p {
    margin: 2vw 0;
  }
  section.container.contact a {
    display: inline-block;
    padding: 3.4vw 0;
  }
  section.container.contactform {
    background-position: 70vw 10vw;
    background-size: 19.111vw 13.111vw;
    height: auto;
  }
  section.container.contactform img {
    float: none;
    margin-top: 30vw;
    margin-right: 0;
  }
  section.container.contactform h2 {
    padding-right: 25vw;
  }
  section.container.contactform h3 {
    display: none;
  }
  section.container.contactform a, section.container.contactform a:link, section.container.contactform a:visited {
    font-size: 7em;
    padding: 1.1em 2.1em 1em 0.8em;
    position: absolute;
    top: auto;
    bottom: 7.5vw;
    left: 7.5vw;
  }
  section.container.contactform a:after, section.container.contactform a:link:after, section.container.contactform a:visited:after {
    background-size: 1.9444vw 4.1664vw;
    width: 1.9444vw;
    height: 4.1664vw;
    right: 2.4996vw;
    top: 5.8vw;
  }
  section.container.news {
    background: #fff;
  }
  section.container.news header {
    background: none;
    height: auto !important;
  }
  section.container.news aside {
    margin: 0;
    width: 100%;
    height: auto;
    position: static;
    padding: 8em 0;
  }
  section.container.news aside h2 {
    font-size: 10em;
    font-family: BrandonText-Regular;
    padding: 0 1em 0.3em 1em;
  }
  section.container.news aside p {
    font-size: 7em;
    padding: 0 2em;
  }
  section.container.news div.slideshow {
    margin-left: 0;
    width: 100%;
    height: 97vw;
    border-right: none;
    margin-bottom: 0;
  }
  section.container.news article {
    position: relative;
    font-size: 4em;
    padding-bottom: 3.8em;
    border-right: 10px solid #fff;
    height: 94vw;
    width: 10%;
  }
  section.container.news article h3 {
    font-size: 2.8em;
    width: 90vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  section.container.news article p {
    margin-bottom: 0.5em;
  }
  section.container.news article a.more {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    background: #fc9700;
    padding: 0.5em 1em;
    width: auto;
    height: auto;
    text-indent: 0;
  }
  section.container.news nav {
    display: none;
  }
  section.centered-content {
    width: 100%;
  }
  section.centered-content h2 {
    margin-left: 20px;
  }
  section.centered-content img {
    max-width: 100%;
    margin-top: 0;
  }
  section.centered-content h3 {
    margin-top: 0.8em;
    margin-bottom: 0;
  }
  section.centered-content h3, section.centered-content p {
    margin-left: 20px;
    margin-right: 20px;
  }
  section.centered-content p {
    width: auto;
  }
  section.plus-des-monats-show time {
    margin-left: 20px;
  }
  section.plus-des-monats-show img {
    margin: 0;
    float: none;
  }
  section.plus-des-monats-show hr {
    margin: 2em 20px;
  }
  section.plus-des-monats-show div.prices {
    margin-right: 20px;
  }
  body.plus_of_months.newest section.container.plus-des-monats {
    display: none;
  }
  section.philosophie {
    font-size: 2.7777vw;
  }
  section.philosophie h2 {
    top: 52vw;
    width: 46vw;
    margin-left: -23vw;
  }
  section.philosophie img {
    width: 90%;
  }
  body.site.philosophie section.container.philo {
    display: none;
  }
  section.zustellungen h2 {
    margin-bottom: 0.5em;
  }
  section.zustellungen h3 {
    margin-top: 1em;
  }
  section.zustellungen section.info {
    float: none !important;
    width: 100% !important;
  }
  section.zustellungen section.contact {
    float: none !important;
    width: 100% !important;
  }
  section.apo_mobile h2 {
    margin-bottom: 0.5em;
  }
  section.apo_mobile h3 {
    margin-left: 0;
  }
  section.apo_mobile img.phone {
    float: none;
    width: 260px;
    margin: 0 20px 2em 20px;
  }
  section.apo_mobile div.app {
    margin-left: 20px;
    background: none;
    padding-left: 0;
  }
  section.impressum h2 {
    margin-left: -100px;
  }
  section.impressum a.close {
    top: 20px;
    right: 15px;
  }
  section.newsletter-abo {
    width: auto;
    padding: 0 20px;
  }
  section.newsletter-abo h1 {
    font-size: 2.8em;
  }
  section.newsletter-abo label {
    font-size: 2em;
  }
  section.newsletter-abo .field:nth-child(3) label:not(:first-child):after {
    content: " ";
    display: block;
    height: 0;
  }
  section.newsletter-abo span {
    display: block;
  }
  section.newsletter-abo input[type=submit] {
    margin-top: 1em;
  }
  section.newsletter-abo input[type=checkbox] {
    margin-left: 20px;
  }
  section.newsletter-abo .terms-of-service label {
    font-size: 1rem;
    width: 85%;
  }
  footer {
    padding-top: 15em;
    padding-bottom: 15em;
  }
  footer p, footer nav {
    float: none;
    margin-bottom: 1em;
    margin-left: 2em;
  }
  footer p a, footer nav a {
    display: inline-block;
    line-height: 28px;
    margin: 10px 0;
  }
  footer p {
    font-size: 6.48em;
  }
  footer nav {
    font-size: 6.48em;
  }
  footer div.newsletter {
    margin-left: 2em;
    float: none;
    font-size: 6.48em;
    width: auto;
  }
  footer div.newsletter a, footer div.newsletter a:link, footer div.newsletter a:visited {
    display: inline-block;
    padding-right: 3.5em;
  }
  footer img {
    float: none;
    width: 40vw;
    margin-left: 13em;
    margin-bottom: 10em;
  }
}
@media screen and (max-width: 1023px) and (orientation: landscape) {
  html.touch-available.landscape body * {
    display: none !important;
  }
  html.touch-available.landscape body {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background: url("turn_device.png") no-repeat center center;
    background-size: cover;
  }
}
.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  outline: 0 !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: 0;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-bottom .gslide-media, .desc-top .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gclose svg, .gnext svg, .gprev svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: 0.1;
}

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: 400;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
